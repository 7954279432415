body {
  background: rgb(7,11,20);
  background: radial-gradient(circle, rgb(19, 49, 105) 0%, rgba(7,11,20,1) 100%);
}
.App {
  text-align: center;
  margin: 10px;
}

.ui.red.button, .ui.red.buttons .button {
  background-color: #8a0d0d;
}

.ui.positive.button, .ui.positive.buttons .button {
  background-color: #1a8032;
}

.ui.inverted.segment, .ui.primary.inverted.segment {
  background: #1b1c1d99;
  color: hsla(0,0%,100%,.9);
}

.menu-button {
  margin-top: 5px;
}

.right-floating {
  float: right;
}

.left-floating {
  float: left;
}

.ui.table, .ui.form {
  font-size: 1.2em;
}

.ui.dropdown .menu>.item {
  -webkit-touch-callout: none;
  border: none;
  box-shadow: none;
  color: rgba(0,0,0,.87);
  cursor: pointer;
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  height: auto;
  line-height: 1em;
  padding: .78571429rem 1.14285714rem!important;
  position: relative;
  text-align: left;
  text-transform: none;
}

.message {
  padding: 10px;
  max-width: 500px;
  width: 100%;
  height: auto;
}

.bottom-right {
  position: absolute;
  right: 0px;
}

input {
  background-color: #181818 !important;
  color: white !important;
}

label {
  color: white !important;
  padding: 5px 0;
}

textarea {
  background-color: #181818 !important;
  color: white !important;
}

.ui.fluid.selection.scrolling.dropdown {
  background-color: #181818 !important;
  color: white !important;
}

.ui.selection.visible.dropdown>.text:not(.default) {
  color: white !important;
}

.item {
  background-color: #181818;
  color: white !important;
}

.ui.dropdown .menu {
  background-color: #4b4b4b;
}

.ui.modal {
  background: #000;
}
.ui.modal > .content {
  background-color: #222222;
  color: white;
}

.ui.modal > .header {
  background-color: #222222;
  color: white;
}

.ui.modal > .actions {
  background-color: #222222;
  color: white;
}

.ui.modal > .content {
  background-color: #222222;
}